import React from "react";
import { useRef } from "react";
import "./App.css";
import Home from "./components/Home.js";
import Footer from "./components/Footer.js";
import Product from "./components/Pdp.js";
import Plp from "./components/Plp.js";
import Catalogue from "./components/Catalogue.js";
import Areatecnica from "./components/Areatecnica.js";
import Instagram from "./components/Instagram.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header.js";
import NotFound from "./components/NotFound";
import Seguridad from "./components/Seguridad.js";
import Terminos from "./components/Terminos.js";

function App() {
  const catalogosection = useRef(null);
  const instalacionsection = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
     
      <Instagram></Instagram>
      <Router>
      <Header
        scrollToSection={scrollToSection}
        catalogosection={catalogosection}
        instalacionsection={instalacionsection}
      ></Header>
        <Routes>
          <Route path="/" element={<Home instalacionsection={instalacionsection} catalogosection={catalogosection}/>} />
          <Route path="/categoria/:category" element={<Plp />} />
          <Route path="/productos/" element={<Plp />} />
          <Route
            path="/productos/:subcategory/:productName"
            element={<Product />}
          />
          <Route path="*" element={<NotFound />} />

          <Route path="/categoria/:category/:subcategory" element={<Plp />} />
          <Route path="/search/:input" element={<Plp />} />
          <Route path="/search/:input/:filters" element={<Plp />} />
          {/*<Route path="/area-tecnica" element={<Areatecnica/>} />
          <Route path="/catalogo" element={<Catalogue/>} />*/}
          <Route path="/seguridad-de-datos" element={<Seguridad />} />
          <Route path="/terminos-condiciones" element={<Terminos />} />
        </Routes>
      </Router>
      <Footer></Footer>
    </>
  );
}

export default App;
