import React, { useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import video1Poster from "../assets/home/video1Thumbail.jpeg";
import video1Poster2 from "../assets/home/video2Thumbail.png";
import "react-alice-carousel/lib/alice-carousel.css";
import Video from "../assets/home/video1.mp4";
import Video2 from "../assets/home/video2.mp4";
import Video3 from "../assets/home/video3.mp4";

import "./Carousel.css";

function Carousel() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      const controlR = document.getElementsByClassName(
        "alice-carousel__next-btn-item"
      );
      const controlL = document.getElementsByClassName(
        "alice-carousel__prev-btn-item"
      );

      const handleClick = () => {
        console.log("3");
        const videos = document.querySelectorAll(".video-gallery");
        videos.forEach((video) => video.pause());
      };

      controlR[1]?.addEventListener("click", handleClick);
      controlL[1]?.addEventListener("click", handleClick);

      // Cleanup
      return () => {
        controlR[1]?.removeEventListener("click", handleClick);
        controlL[1]?.removeEventListener("click", handleClick);
      };
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };

  const items = [
    <video
      key="1"
      controls
      className="video-gallery"
      src={Video}
      type="video/mp4"
      poster={video1Poster}
    />,
    <video
      key="2"
      controls
      className="video-gallery"
      src={Video2}
      type="video/mp4"
      poster={video1Poster2}
    />,
    <video
      key="3"
      controls
      className="video-gallery"
      src={Video3}
      type="video/mp4"
    />,
  ];

  return (
    <section className="carousel-video-contaniner">
      <AliceCarousel
        responsive={responsive}
        disableDotsControls
        items={items}
        onSlideChanged={() => {
          const videos = document.querySelectorAll(".video-gallery");
          videos.forEach((video) => video.pause());
        }}
      />
    </section>
  );
}

export default Carousel;
