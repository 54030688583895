import "./Home.css";
import "./GalleryGrid.css";
import React, { useState } from "react";
import galleryVigas from "../assets/home/gallery-vigas.jpg";
import galleryDeck from "../assets/home/gallery-deck.jpg";
import galleryLvt from "../assets/home/gallery-lvt.jpg";
import galleryPanelDecorativo from "../assets/home/gallery-paneldecorativo.jpg";
import galleryspc from "../assets/home/gallery-spc.jpg";
import gallerywpc from "../assets/home/gallery-wpc.jpg";
import Imageview from "./ImageView";

import vigasmain from "../assets/home/gallery-vigas.jpg";
import vigas1 from "../assets/home/vigas1.jpg";
import vigas2 from "../assets/home/vigas2.jpg";
import vigas3 from "../assets/home/vigas3.jpg";
import vigas4 from "../assets/home/vigas4.jpg";


import wpcmain from "../assets/home/gallery-wpc.jpg";
import wpc1 from "../assets/home/wpc1.jpg";
import wpc2 from "../assets/home/wpc2.jpg";

import dockmain from "../assets/home/gallery-deck.jpg";
import deck1 from "../assets/home/deck1.jpg";
import deck2 from "../assets/home/deck2.jpg";

import panelmain from "../assets/home/gallery-paneldecorativo.jpg";
import panel1 from "../assets/home/panel1.jpg";
import panel2 from "../assets/home/panel2.jpg";
import panel3 from "../assets/home/panel3.jpg";
import panel4 from "../assets/home/panel4.jpg";
import panel5 from "../assets/home/panel5.jpg";

import spcmain from "../assets/home/gallery-spc.jpg";
import spc1 from "../assets/home/spc1.jpg";
import spc2 from "../assets/home/spc2.jpg";
import spc3 from "../assets/home/spc3.jpg";

import lvtmain from "../assets/home/gallery-lvt.jpg";
import lvt1 from "../assets/home/lvt1.jpg";
import lvt2 from "../assets/home/lvt2.jpg";

const itemsVigas = [
  <img src={vigasmain} alt="Vigas 1" />,
  <img src={vigas1} alt="Vigas 2" />,
  <img src={vigas2} alt="Vigas 3" />,
  <img src={vigas3} alt="Vigas 4" />,
  <img src={vigas4} alt="Vigas 5" />,
];

const itemsLvt = [
  <img src={lvtmain} alt="Vigas 1" />,
  <img src={lvt1} alt="Vigas 2" />,
  <img src={lvt2} alt="Vigas 3" />,
];


const itemsDock = [
  <img src={dockmain} alt="Dock Main" />,
  <img src={deck1} alt="Dock Main" />,
  <img src={deck2} alt="Dock Main" />,
];

const itemsPanel = [
  <img src={panelmain} alt="Panel Main" />,
  <img src={panel1} alt="Panel" />,
  <img src={panel2} alt="Panel" />,
  <img src={panel3} alt="Panel" />,
  <img src={panel4} alt="Panel" />,
  <img src={panel5} alt="Panel" />,
];

const itemsSpc = [
  <img src={spcmain} alt="spc" />,
  <img src={spc1} alt="spc" />,
  <img src={spc2} alt="spc" />,
  <img src={spc3} alt="spc" />,
];

const itemsWpc = [
  <img src={wpcmain} alt="spc" />,
  <img src={wpc1} alt="spc" />,
  <img src={wpc2} alt="spc" />,
];

function GalleryGrid() {
  const [openGallery, setOpenGallery] = useState(false);
  const [galleryitems, setGalleryItems] = useState([]);


  const openGalleryFunction = (items) => {
    setGalleryItems(items);
    setOpenGallery(true);
  };

  return (
    <>
      <Imageview openGallery={openGallery} setOpenGallery={setOpenGallery} items={galleryitems} />


      <article className="gallery">
        <div className="gallery-1-imgtext" onClick={() => openGalleryFunction(itemsVigas)}>
          <img className="gallery-1" src={galleryVigas} alt="Vigas" />
          <span>Vigas</span>
        </div>

        <div className="gallery-2-imgtext" onClick={() => openGalleryFunction(itemsPanel)} >
          <img className="gallery-2" src={galleryPanelDecorativo} alt="Panel Decorativo" />
          <span>Panel Decorativo</span>
        </div>

        <div className="gallery-3-imgtext" onClick={() => openGalleryFunction(itemsDock)}>
          <img className="gallery-3" src={galleryDeck} alt="Deck"  />
          <span>Deck</span>
        </div>

        <div className="gallery-4-imgtext" onClick={() => openGalleryFunction(itemsLvt)}>
          <img className="gallery-4" src={galleryLvt} alt="LVT" />
          <span>LVT</span>
        </div>

        <div className="gallery-5-imgtext"  onClick={() => openGalleryFunction(itemsSpc)}>
          <img className="gallery-5" src={galleryspc} alt="SPC" />
          <span>SPC</span>
        </div>

        <div className="gallery-6-imgtext" onClick={() => openGalleryFunction(itemsWpc)}>
          <img className="gallery-6" src={gallerywpc} alt="WPC" />
          <span>WPC</span>
        </div>
      </article>
    </>
  );
}

export default GalleryGrid;

/*import "./Home.css";
import "./GalleryGrid.css";
import React, { useState } from "react";
import galleryVigas from "../assets/home/gallery-vigas.jpg";
import galleryDeck from "../assets/home/gallery-deck.jpg";
import galleryLvt from "../assets/home/gallery-lvt.jpg";
import galleryPanelDecorativo from "../assets/home/gallery-paneldecorativo.jpg";
import galleryspc from "../assets/home/gallery-spc.jpg";
import gallerywpc from "../assets/home/gallery-wpc.jpg";
import Imageview from "./ImageView";

import vigasmain from "../assets/home/gallery-vigas.jpg";
import vigas1 from "../assets/home/vigas1.jpg";
import vigas2 from "../assets/home/vigas2.jpg";
import vigas3 from "../assets/home/vigas3.jpg";
import vigas4 from "../assets/home/vigas4.jpg";

import dockmain from "../assets/home/gallery-deck.jpg";
import livtmain from "../assets/home/gallery-lvt.jpg";
import panelmain from "../assets/home/gallery-paneldecorativo.jpg";
import spcmain from "../assets/home/gallery-spc.jpg";
import wpcmain from "../assets/home/gallery-wpc.jpg";


function GalleryGrid() {

  const [openGallery, setOpenGallery] = useState(false);
  const [galleryitems, setGalleryItems] = useState([]);

  const openGalleryFunction = (items) => {
    setOpenGallery(true)
  }

  const itemsVigas = [
    <img src={vigasmain}/>,
    <img src={vigas1} />,
    <img src={vigas2} />,
    <img src={vigas3} />,
    <img src={vigas4} />,
  ];

  const itemsDock = [
    <img src={dockmain}/>,
  ];


  return (
    <>
    <Imageview openGallery={openGallery} setOpenGallery={setOpenGallery} items={itemsVigas}/>
    <article className="gallery">
      <div className="gallery-1-imgtext" onClick={()=>openGalleryFunction(itemsVigas) }>
        <img className="gallery-1" src={galleryVigas} />
        <span>Vigas</span>
      </div>

      <div className="gallery-2-imgtext" onClick={()=>openGalleryFunction(itemsDock)}>
        <img className="gallery-2" src={galleryPanelDecorativo} />
        <span>Panel Decorativo</span>
      </div>

      <div className="gallery-3-imgtext">
        <img className="gallery-3" src={galleryDeck} />
        <span>Deck</span>
      </div>

      <div className="gallery-4-imgtext">
        <img className="gallery-4" src={galleryLvt} />
        <span>LVT</span>
      </div>

      <div className="gallery-5-imgtext">
        <img className="gallery-5" src={galleryspc} />
        <span>SPC</span>
      </div>

      <div className="gallery-6-imgtext">
        <img className="gallery-6" src={gallerywpc} />
        <span>WPC</span>
      </div>
      </article>
    </>
  );
}

export default GalleryGrid;
*/

