import "./Home.css";
import "./Carousel.css";
import "./GalleryGrid.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function Imageview({ openGallery, setOpenGallery, items }) {
  const closeGalleryFunction = () => {
    setOpenGallery(false);
  };

  const responsive = {
    0: { items: 1 },
    650: { items: 1 },
    1024: {
      items: 1,
    },
  };

  return (
    <>
      <section
        className={`container-imageview-contaniner ${
          openGallery ? " " : "delete"
        }`}
      >
        <section className="imageview-contaniner">
          <img
            onClick={() => closeGalleryFunction()}
            className="close-imageview"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADwUlEQVR4nO2aSU8UQRTH24CAwcimLDc9Egj6JVRUEPQG6g0CF5agV8CzejIh8XNo0GgixpXVPVE2TyoXiTcRA+ZnHrwOFWx6urprmIHwTyYZqKpX/1pevW08bw+7FEAp0AzcBIaBaeAn8Ec/8v2ztkmfJqDEywYABcAV4BHwF3usAg+By0B+JhZwALgKLBikloERoF9Pplp2HNivnxL9n7QNAE90jI/vQJ9sznYt4hzwxSAwCbQBRTFkFQPtwJQhbx44k+5rdMeY8DVwyqH8euCtIX/I+ekAlUpc8AvoAnKcTrI+Tw7QAywZp13hSvgxPW701al1Ijh8zjpgRuecEw5JBR4xBE4Ah52xTT13CfBC5xadrEyiE/51egUUOmebmkMhMGZcM3udMRRbrlNpWphG41Fm3IqhOE+sr9hp14mIOrOknOptjJ1vJ7q8LAHQayh/6isGXDPshPMnNi6AXOCdcutN1TlfXQXBSS/LAJxVbguhp6LO29oLYSH8mboY5TGIlevJP4/Yf5/xkraGdRQvVtBmQUbsi+CjzWJ0ETJGMG4xrkPHPAiLJ1bVIy2KSSjSYuKM2WQoJb5ZCeQJXFDBj714HsAHw+5UWfS1ttjAUx3fGNR4Sxv7bQVH3eUkJ2ECuK4ybgQ1SggqOO/FRNjJuDiJgNtzN6hxVhurvQQI2nVXJ+EDqFFZ00GNi9pY5iVEAHFnixCIF67yfniboS+BIM9zgE1XKfF1CjDcguVMLaRqOxayuFuu1uxuUfZhbWxKMMGWT6yN0UwF4GLY8+sbxIGdbhCbtXEkhuDIxs7FybDhojRs5Yz5TmNxFjuNpYbTeGirTpJQFrRbCJ6MSchczITFuE4dcz+s0yXtNGUheDRhYCVjRy0CqzfKsSWVofmmHU97WQagQbl9TVmG0NQ+uvJsSz68V27dUTOMfq63x8sSsLHBM5GLQlKf0EGSFKtLO8vUfE4Av5WTXSlD6xP+DiT2v+KCdbsjSTnB7TgCCoyndSxDSeyDkmFRDuOx64y6G9P+Wy9/O2cbbvhe6tzziQs+WuiZM67ZcWdsw3ViTucUr/yoK8EVxjVb0oRyrhPh/z+xfYZij7uIX4J0xn8A0ISyk+qrWmwpZfh2Yk2x01p71+qrf+xoLrYjzq8YVA86DbfDv0rOqsVRTqfXcGdQj1Tc60HNO9VotSlPP/K9VoOiQe3r5wl8t6M7U7+AEN+sVRLKGgLYYkUj05aMLCAIklCWXKxEbcA9DZoWjR/VyPdPEp5qn8Yt44k9eDsf/wDHx3jfA8slUAAAAABJRU5ErkJggg=="
            alt="cancel"
          ></img>
          <div className="imageview-contaniner-img">
            {openGallery && (
              <AliceCarousel
                key={openGallery ? "open" : "closed"}
                responsive={responsive}
                items={items}
              />
            )}
          </div>
        </section>
      </section>
    </>
  );
}

export default Imageview;
