import logo from "../assets/Logo woodart blanco.png";
import "./Footer.css";
import pinterest from "../assets/home/P 1.png";
import instagram from "../assets/home/IG 1.png";
import facebook from "../assets/home/FB 1.png";
import yt from "../assets/home/YT 1.png";

function Footer() {
  return (
    <footer>
      <img src={logo} className="footer-logo" alt="logo" loading="lazy" />

      <div className="footer-text">
        <span>
          {" "}
          Blvd. Manuel Ávila Camacho Hab, Valle de los Pinos 2000 B, 54040
          Tlalnepantla de Baz, Edo Méx.
        </span>
        <div className="footer-textlinks">
          <a href="/seguridad-de-datos">Seguridad de datos</a>
          <span>|</span>
          <a href="/terminos-condiciones">Términos y condiciones</a>
        </div>
        ®2024 Woodart - Todos los derechos reservados
      </div>

      <div className="footer-allicons">
        <a
          href="https://www.facebook.com/profile.php?id=61566842943976"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer-icon" src={facebook} alt="fb" loading="lazy" />
        </a>
        <a
          href="https://www.pinterest.com.mx/woodart_oficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-icon"
            src={pinterest}
            alt="pinterest"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.instagram.com/wood_art_oficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-icon"
            src={instagram}
            alt="instagram"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UC0GiZp_MwPanf6yDnACi_tw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-icon"
            src={yt}
            alt="yt"
            loading="lazy"
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
