import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Nero from "../assets/home/Nero_WPC.webp";
import Azur from "../assets/home/Azur_WPC.webp";
import Cherry from "../assets/home/Cherry_WPC.webp";
import Cinnamon from "../assets/home/Cinnamon_WPC.webp";
import Maple from "../assets/home/Maple_WPC.webp";
import Neve from "../assets/home/Neve_WPC.webp";
import Nogal from "../assets/home/Nogal_WPC.webp";
import Oxford from "../assets/home/Oxford_WPC.webp";
import Roble from "../assets/home/Roble_WPC.webp";
import Tekka from "../assets/home/Tekka_WPC.webp";
import Walnut from "../assets/home/Walnut_WPC.webp";

import "./Carousel.css";

function Carousel() {
  const responsive = {
    0: { items: 1 },
    650: { items: 3 },
    1024: {
      items: 5,
    },
  };

  const images = [
    Nero,
    Azur,
    Cherry,
    Cinnamon,
    Maple,
    Neve,
    Nogal,
    Oxford,
    Roble,
    Tekka,
    Walnut,
  ];
  const texts = [
    "Nero",
    "Azur",
    "Cherry",
    "Cinnamon",
    "Maple",
    "Neve",
    "Nogal",
    "Oxford",
    "Roble",
    "Tekka",
    "Walnut",
  ];

  const items = images.map((image, index) => (
    <a href={`/productos/quattro/${texts[index]}`} key={index} className="carousel-product">
      <div className="carousel-imgcontainer">
        <img src={image} className="sliderimg" alt={`Slide ${index}`} />
        <span className="carousel-circle"></span>
      </div>
      <div className="slidercontain-text">
        <span>WPC Quattro</span>
        <span className="slidertext">{texts[index]}</span>
      </div>
    </a>
  ));

  return ( 
    <>
      <section className="slider-contaniner">
        <AliceCarousel
          responsive={responsive}
          disableDotsControls={true}
          items={items}
        />
      </section>
    </>
  );
}

export default Carousel;
