import mainImage from "../assets/home/main-home.jpg";
import "../App.css";
import "./Home.css";
import catalogo from "../assets/home/catalogo.png";
import qr from "../assets/home/qr.jpg";
import Carousel from "./Carousel.js";
import CarouselVideo from "./CarouselVideo.js";
import GalleryGrid from "./GalleryGrid.js";

function Home({ catalogosection, instalacionsection }) {



  return (
    <>
      <section>
        <section className="main-section">
          <h1>
            Hola,<br></br>somos Woodart
          </h1>
          <img className="main-image" src={mainImage} />
        </section>

        <div className="home-container">
          <p className="description">
            En Woodart, transformamos tus espacios con elegancia y versatilidad.
            Nuestra amplia gama de productos está diseñada para ofrecerte
            soluciones decorativas que combinan estética y funcionalidad,
            perfectas para cualquier ambiente.
          </p>
          <Carousel />
        </div>

        <section className="section-home">
          <article className="section-title">
            <h2 className="title-line">Detalles que inspiran tu vida</h2>
            <div className="title-line-line"></div>
          </article>
          <section className="section-menu-gallery">
            <article>
              <h3>
                Lleva tu estilo y crea espacios modernos y elegantes con
                nuestros productos
              </h3>
              <span>
                Contamos con gran variedad de aplicaciones, incluyendo
                revestimientos interiores, decoración de paredes, y mejoras en
                espacios comerciales. Ya sea que desees dar un toque moderno a
                tu salón, o crear un ambiente acogedor en tu oficina, tenemos la
                solución perfecta para ti.
              </span>
            </article>

            <GalleryGrid />
          </section>
        </section>

        <section ref={instalacionsection} className="section-home">
          <article className="section-title">
            <h2 className="title-line">Instalación</h2>
            <div className="title-line-line"></div>
          </article>
          <CarouselVideo></CarouselVideo>
        </section>

        <section ref={catalogosection} className="section-home">
          <article className="section-title">
            <h2 className="title-line">Catálogo</h2>
            <div className="title-line-line"></div>
          </article>
          <article className="catalogo-section">
            <div>
              <h3>Haz click para ver el catálogo:</h3>
              <a href="https://www.woodart.com.mx/catalogos/WoodArt_2025.pdf">
              <img className="catalogoimg" src={catalogo} />
              </a>
            </div>

            <div className="catalogoqr">
              <img src={qr} />
              <div className="scan-text">
                <span></span>
                <h3>ESCANÉAME</h3>
              </div>
            </div>

            <div className="catalogobtn">
              <a href="https://www.woodart.com.mx/catalogos/WoodArt_2025.pdf">
                <h2>DESCARGAME</h2>
              </a>
            </div>
          </article>
        </section>
      </section>
    </>
  );
}

export default Home;
